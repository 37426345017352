import React from "react";

export const WIDTH = "125rem";

const Container = React.forwardRef(function Container(
  { width = WIDTH, style, ...props },
  ref
) {
  return (
    <div
      ref={ref}
      style={{
        width,
        maxWidth: "100%",
        margin: "0 auto",
        ...style,
      }}
      {...props}
    />
  );
});

export default Container;
