import React from "react";
import { css } from "@emotion/react";

const HPad = ({ children }) => (
  <div
    css={css`
      width: 100%;
      padding: 0 1.5rem;

      @media (min-width: 640px) {
        padding: 0 3rem;
      }

      @media (min-width: 960px) {
        padding: 0 6rem;
      }
    `}
  >
    {children}
  </div>
);

export default HPad;
