// const BLUE = "#1A61E9";
export const ORANGE = "#FC7655";

export const BG = "#FFFAEE";
export const BORDER = "rgb(0 0 0 / 15%)";
export const PRIMARY = ORANGE;

export const YELLOW_BG_DARK_TINT = "#FFF4D9";

export const BACKGROUND_PRIMARY = "white";
export const BACKGROUND_SECONDARY = "#F4F3EF";
export const BACKGROUND_SECONDARY_DARKER = "#DEDECE";
export const BACKGROUND_SECONDARY_LIGHTER = "#FBFAF9";
export const BACKGROUND_LIGHT_ORANGE_TINT = "#FDF8F7";

export const YELLOW_DARK = "#95954C";
export const YELLOW_DARKEST = "#636327";

export const BLUE = "#0024F5";
