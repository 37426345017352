import { css } from "@emotion/react";
import React from "react";
import Head from "next/head";
import Image from "next/image";
import {
  BACKGROUND_PRIMARY,
  BACKGROUND_SECONDARY,
  BACKGROUND_SECONDARY_DARKER,
  PRIMARY,
  YELLOW_DARK,
  YELLOW_DARKEST,
} from "../constants/colors";
import T from "../components/Text";
import Header from "../components/Header";
import Container from "../components/Container";
import HPad from "../components/HPad";
import Icon from "../components/Icon";
import Button from "../components/Button";
// import Banner from "../components/Banner";
import content from "../content/guest-landing.json";

import gridImage1 from "../public/fancy-desk-standing.jpeg";
import gridImage2 from "../public/table-talk-square.jpg";
import gridImage3 from "../public/collab.jpeg";
import avatarImage from "../public/landing/avatar-illustration.png";
import backImage from "../public/bye.gif";

const BREAKPOINT = "960px";

export default function Home({}) {
  return (
    <>
      <Head>
        <title>Satchel</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div
        css={css`
          background: ${BACKGROUND_PRIMARY};
        `}
      >
        <HPad>
          <Container>
            <TopSection />
          </Container>
        </HPad>
      </div>
    </>
  );
}

const TopSection = () => {
  return (
    <>
      <div
        css={css`
          min-height: 100vh;
          display: flex;
          align-items: center;
          padding: 3rem 0;
          text-align: center;

          p {
            margin: 0 auto 3.2rem;
            font-size: 1.8rem;
            font-weight: 500;
          }

          @media (min-width: 600px) {
            padding: 7rem 0;

            p {
              font-size: 2.7rem;
            }
          }

          h1 {
            font-size: 8vw;
            font-size: max(8vw, 2rem);
            line-height: 1.15;
            margin: 0 auto 3rem;
          }
          @media (min-width: 420px) {
            h1 {
              font-size: 4.2rem;
            }
          }
          @media (min-width: 600px) {
            h1 {
              font-size: 6.2rem;
            }
          }
        `}
      >
        <div
          style={{ padding: "0 0 6rem", overflow: "auto", margin: "0 auto" }}
        >
          <div
            css={css`
              width: 32rem;
              max-width: 100%;
              margin: 0 auto 7rem;
            `}
          >
            <Image src={backImage} layout="responsive" alt="" />
          </div>
          <h1>Satchel is no more</h1>
          <T
            block
            component="p"
            lineHeight={1.45}
            css={css`
              width: 80rem;
              max-width: 100%;
            `}
          >
            <p>
              A huge thanks to everyone who helped us along the way — we
              couldn’t do it without, nor with you.
            </p>
          </T>
        </div>
      </div>
    </>
  );
};
