import React from "react";
import { css } from "@emotion/react";
import { useIsSSR } from "@react-aria/ssr";
import { useUser } from "@auth0/nextjs-auth0";
import { useRouter } from "next/router";
import Link from "next/link";
import { BACKGROUND_SECONDARY_DARKER } from "../constants/colors";
import Button from "./Button";
import Dialog from "./Dialog";
import Container from "./Container";
import Logo from "./Logo";
import Cross from "./Cross";
import Icon from "./Icon";
import T from "./Text";
import HPad from "./HPad";

const HAMBURGER_MENU_BREAKPOINT = "640px";

const defaultLeftMenuItems = [
  {
    label: "Explore spaces",
    url: "/listings",
    // isVisible: ({ user }) => user == null,
  },
  {
    label: "Pricing",
    url: "/pricing",
    isVisible: ({ pathname }) => pathname === "/",
  },
  {
    label: "Become a host",
    url: "/host",
    isVisible: ({ user, pathname }) => pathname === "/" || user == null,
  },
];

const defaultRightMenuItems = ({ isBelowFold }) => [
  {
    label: "Login",
    url: `/login?returnTo=${encodeURIComponent("/me")}`,
    isVisible: ({ user }) => user == null,
  },
  {
    label: "Sign up",
    type: "button",
    variant: isBelowFold ? "primary" : "light",
    url: `/login?screenHint=signup&returnTo=${encodeURI("/me?ref=header")}`,
    isVisible: ({ user }) => user == null,
  },
  {
    label: "Bookings",
    url: "/me?t=reservations",
    isVisible: ({ user, pathname }) =>
      user != null && pathname.indexOf("/me") !== 0,
  },
  {
    label: "Account",
    renderLabel: function Label({ user }) {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            alignItems: "center",
            gridGap: "1rem",
          }}
        >
          <span>Account</span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "3.2rem",
              height: "3.2rem",
              borderRadius: "50%",
              background: BACKGROUND_SECONDARY_DARKER,
              textTransform: "uppercase",
              lineHeight: 1,
              fontWeight: "400",
            }}
          >
            {user.name?.[0] ?? user.email[0]}
          </div>
        </div>
      );
    },
    url: "/me",
    isVisible: ({ user }) => user != null,
  },
];

export const useScrollObserver = (onScroll) => {
  const callbackRef = React.useRef();
  React.useEffect(() => {
    callbackRef.current = onScroll;
  });
  React.useEffect(() => {
    const handleScroll = () => callbackRef.current();
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
};

export const HEIGHT = "6rem";

const Header = ({
  sticky = true,
  compact = false,
  leftMenuItems = defaultLeftMenuItems,
  rightMenuItems: rightMenuItems = defaultRightMenuItems,
  logoColor = "black",
  logoHeight,
}) => {
  const [isFixed, setFixed] = React.useState(false);
  const [isBelowFold, setIsBelowFold] = React.useState(false);

  const isSSR = useIsSSR();
  const router = useRouter();

  const { user } = useUser();

  const [isMenuVisible, setMenuVisible] = React.useState(false);

  const [filteredLeftMenuItems, filteredRightMenuItems] = [
    leftMenuItems,
    rightMenuItems,
  ]
    .map((items) =>
      typeof items === "function" ? items({ isFixed, isBelowFold }) : items
    )
    .map((items) =>
      items.filter(
        (i) => i.isVisible?.({ user, pathname: router.asPath }) ?? true
      )
    );

  // The threshold where we swtich to sticky header
  const offset = 30;

  useScrollObserver(() => {
    if (window.scrollY <= offset && !isFixed) return;
    if (window.scrollY > offset && isFixed) return;
    setFixed(window.scrollY > offset);
  });

  useScrollObserver(() => {
    if (isBelowFold && window.scrollY > window.innerHeight) return;
    if (!isBelowFold && window.scrollY <= window.innerHeight) return;
    setIsBelowFold(window.scrollY > window.innerHeight);
  });

  React.useEffect(() => {
    setFixed(window.scrollY > offset);
    setIsBelowFold(window.scrollY > window.innerHeight);
  }, []);

  const content = (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;

        will-change: height;
        transition: 0.2s height ease-out;
        height: ${HEIGHT};

        @media (min-width: ${HAMBURGER_MENU_BREAKPOINT}) {
          display: grid;
          grid-template: "logo left-menu empty-space right-menu";
          grid-template-columns: minmax(0, auto) minmax(0, auto) 1fr minmax(
              0,
              auto
            );
          grid-gap: 1rem 3rem;
        }
      `}
    >
      <div
        style={{
          gridArea: "logo",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Link href="/">
          <a className="logo" style={{ display: "block" }}>
            <Logo
              color={logoColor}
              height={null}
              css={css`
                transition: 0.25s height ease-out;
                will-change: height;
                height: 2.2rem;

                @media (min-width: 640px) {
                  height: ${logoHeight ??
                  (compact || isFixed ? "2.2rem" : "2.6rem")};
                }
              `}
            />
          </a>
        </Link>
      </div>

      <div
        css={css`
          grid-area: left-menu;
          display: none;
          @media (min-width: ${HAMBURGER_MENU_BREAKPOINT}) {
            display: block;
          }
        `}
      >
        <Menu compact={compact} user={user} items={filteredLeftMenuItems} />
      </div>

      <div
        css={css`
          grid-area: right-menu;
          display: none;
          @media (min-width: ${HAMBURGER_MENU_BREAKPOINT}) {
            display: block;
          }
        `}
      >
        <Menu compact={compact} user={user} items={filteredRightMenuItems} />
      </div>

      <button
        onClick={() => setMenuVisible(true)}
        css={css`
          display: flex;
          align-items: center;
          padding: 0;
          background: none;
          border: 0;
          cursor: pointer;

          @media (min-width: ${HAMBURGER_MENU_BREAKPOINT}) {
            display: none;
          }
        `}
      >
        <T block size="1.4rem" style={{ marginRight: "1rem" }}>
          Menu
        </T>
        <Icon name="menu" size="2.6rem" />
      </button>
    </div>
  );

  return (
    <>
      {sticky ? (
        <>
          {!compact && (
            <div
              style={{ height: `${offset}px` }}
              css={css`
                display: none;
                @media (min-width: ${HAMBURGER_MENU_BREAKPOINT}) {
                  display: block;
                }
              `}
            />
          )}
          <div
            css={css`
              position: ${isFixed ? "fixed" : "static"};
              top: 0;
              left: 0;
              width: 100%;
              z-index: 2;
              transition: 0.1s all;
            `}
            style={{ background: isFixed ? "white" : "transparent" }}
          >
            <HPad>
              <Container>{content}</Container>
            </HPad>
          </div>
          {!compact && (
            <div
              style={{ height: `${offset}px` }}
              css={css`
                display: none;
                @media (min-width: ${HAMBURGER_MENU_BREAKPOINT}) {
                  display: block;
                }
              `}
            />
          )}

          <div style={{ height: isFixed ? HEIGHT : 0 }} />
        </>
      ) : (
        content
      )}

      {!isSSR && isMenuVisible && (
        <Dialog
          isOpen
          onClose={() => setMenuVisible(false)}
          isDismissable
          style={{
            width: "100%",
            minHeight: "100vh",
            background: "white",
            margin: 0,
          }}
        >
          <div
            css={css`
              position: relative;
              padding: 3rem;
            `}
          >
            <Container>
              <div
                css={css`
                  position: absolute;
                  top: 2rem;
                  right: 2rem;
                `}
              >
                <button
                  onClick={() => setMenuVisible((s) => !s)}
                  style={{
                    display: "block",
                    padding: "0.5rem",
                    border: 0,
                    background: "none",
                  }}
                >
                  <Cross size="2rem" />
                </button>
              </div>
              <div style={{ padding: "0 0 3rem" }}>
                {[...filteredLeftMenuItems, ...filteredRightMenuItems].map(
                  (i) => (
                    <T
                      key={i.url}
                      block
                      weight="500"
                      margin="0 0 1.5rem"
                      component="a"
                      href={i.url}
                    >
                      {i.label}
                    </T>
                  )
                )}
              </div>
            </Container>
          </div>
        </Dialog>
      )}
    </>
  );
};

const Menu = ({ user, compact, items }) => {
  return (
    <div
      css={css`
        display: grid;
        grid-gap: 4rem;
        grid-gap: 3rem;
        grid-auto-flow: column;
        align-items: center;
        justify-content: flex-start;

        white-space: nowrap;
        padding: 1rem 0;
        overflow: auto;
      `}
    >
      {items.map((i) => (
        <Link href={i.url} key={i.url}>
          {i.type === "button" ? (
            <Button
              component="a"
              size="small"
              href={i.url}
              variant={i.variant ?? "light"}
              style={{
                fontSize: compact ? "1.5rem" : "1.6rem",
                padding: "0 1.8rem",
                fontWeight: "600",
              }}
            >
              {i.label}
            </Button>
          ) : (
            <T
              component="a"
              size={compact ? "1.5rem" : "1.6rem"}
              weight={compact ? "500" : "500"}
              href={i.url}
              css={css`
                position: relative;
                :hover {
                  color: rgb(0 0 0 / 70%);
                }
              `}
            >
              {typeof i.renderLabel === "function"
                ? i.renderLabel({ user })
                : i.label}
            </T>
          )}
        </Link>
      ))}
    </div>
  );
};

export default Header;
