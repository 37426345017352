import React from "react";
import { css } from "@emotion/react";

const base = {
  transition: "0.1s opacity",
};

const label = {
  fontSize: "1.1rem",
  lineHeight: 1.3,
  fontWeight: "700",
  letterSpacing: "0.05em",
  textTransform: "uppercase",
};
const paragraph = {
  lineHeight: 1.3,
};
const paragraphSmall = {
  ...paragraph,
  fontSize: "1.3rem",
};

const LIGHT_ON_LIGHT = "rgb(0 0 0 / 54%)";

const colorByName = {
  grey: LIGHT_ON_LIGHT,
};

const stylesByVariant = {
  label,
  "label-light": {
    ...label,
    color: "rgba(0,0,0,0.54)",
  },
  paragraph,
  "paragraph-light": { ...paragraph, color: LIGHT_ON_LIGHT },
  "paragraph-small": paragraphSmall,
  "paragraph-small-light": { ...paragraphSmall, color: LIGHT_ON_LIGHT },
  "paragraph-tiny-light": {
    ...paragraph,
    fontSize: "1.2rem",
    color: LIGHT_ON_LIGHT,
  },
  "paragraph-large": {
    fontSize: "1.8rem",
    lineHeight: 1.4,
  },
  headline: {
    fontSize: "2.2rem",
    fontWeight: "700",
  },
  "headline-large": {
    display: "block",
    fontSize: "2.8rem",
    fontWeight: "700",
  },
  "headline-small": {
    fontSize: "1.6rem",
    fontWeight: "700",
  },
  "number-large": {
    fontSize: "2.4rem",
    fontWeight: "800",
  },
  link: {
    textDecoration: "underline",
    color: "var(--text-grey)",
    ":hover": {
      color: "rgb(0 0 0 / 45%)",
    },
  },
};

const defaultComponentByVariant = {
  paragraph: "p",
};

const Text = React.forwardRef(function Text(
  {
    variant,
    color,
    size,
    weight,
    margin,
    align,
    lineHeight,
    block = false,
    italic = false,
    bold = false,
    extraBold = false,
    truncate = false,
    underline = false,
    hide = false,
    component = "span",
    css: customCss,
    ...props
  },
  ref
) {
  if (variant && !stylesByVariant[variant])
    throw new Error(`Unknown variant "${variant}"`);

  const Component = defaultComponentByVariant[variant] || component;

  const style = { ...base, ...stylesByVariant[variant] };

  if (size) style.fontSize = size;
  if (weight) style.fontWeight = weight;
  if (color) style.color = colorByName[color] ?? color;
  if (lineHeight) style.lineHeight = lineHeight;
  if (margin != null) style.margin = margin;
  if (italic) style.fontStyle = "italic";
  if (bold) style.fontWeight = "600";
  if (extraBold) style.fontWeight = "700";
  if (align) style.textAlign = align;
  if (block) style.display = "block";
  if (truncate) {
    style.whiteSpace = "nowrap";
    style.overflow = "hidden";
    style.textOverflow = "ellipsis";
  }
  if (underline) style.textDecoration = "underline";
  if (hide) style.opacity = 0;

  return <Component ref={ref} {...props} css={[css(style), customCss]} />;
});

export default Text;
